import { useMutation } from 'react-query';
import { useSiteId } from '../../context/SiteIdContext';

export function useMovieQueue() {
    const site = useSiteId();
    
    return useMutation((movieId: string) => fetch(`/api/movies/${movieId}/queue?site=${site}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    }).then(res => res.text()));
}
