import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CollectionsIcon from '@mui/icons-material/Collections';
import HomeIcon from '@mui/icons-material/Home';
import { FC } from 'react';
import { BottomBarButton } from './Bottom-Bar-Button';

export const BottomBar: FC = () => {
    return (
        <div className="bottom-bar">
            <BottomBarButton route="/gallery" icon={<CollectionsIcon />} />
            <BottomBarButton route="/home" icon={<HomeIcon />} />
            <BottomBarButton route="/editor" icon={<AddCircleOutlineIcon />} />
        </div>
    );
};
