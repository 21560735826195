import { FC, useCallback } from "react";
import { Preview } from '../../components/Preview/Preview';
import { EditorPageProps } from './editor.types';

interface EditorPreviewProps extends EditorPageProps {
    id: string;
    onRework: () => void;
    onQueue: (movieId: string) => void;
    progress: number;
}

export const EditorPreview: FC<EditorPreviewProps> = ({ id, onRework, onQueue, progress }) => {
    const handleQueue = useCallback(() => onQueue(id), [onQueue, id]);

    return (
        <>
            <div className="page-container">
                <Preview movieId={id}></Preview>
            </div>
            <div className="page-actions">
                <button className="secondary" type="button" onClick={onRework}>Überarbeiten</button>
                <button className="primary" type="button" onClick={handleQueue}>Abspielen</button>
            </div>
        </>
    );
};
