import { FC } from "react";
import { BottomBar } from '../components/Bottom-Bar/Bottom-Bar';
import { Page } from "../components/Page/Page";
import {useMovies} from "../api";
import {MovieList} from "../components/Movie-List/Movie-List";

export const Gallery: FC = () => {

    const {data: movies} = useMovies();

    return (
        <Page title="Galerie"
            footer={(
                <BottomBar />
            )}
        >

            <MovieList movies={movies} path="/gallery" />
        </Page>
    );
};
