import { createContext, FC, PropsWithChildren, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useWebsocket } from '../api/notifications/use-websocket';
import { useWebsocketListener } from '../api/notifications/use-websocket-listener';
import { useSiteId } from './SiteIdContext';

export const MoviesContext = createContext<undefined>(undefined);

export const MoviesProvider: FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient();
    const siteId = useSiteId();

    const [socket] = useWebsocket();
    useWebsocketListener(socket, 'message', useCallback((event) => {
        const message = (event.data as string);

        if (message.startsWith('NEW_MOVIE')) queryClient.invalidateQueries([siteId, 'movies', 'list']);
        if (message.startsWith('QUEUE_MOVIE')) queryClient.invalidateQueries([siteId, 'playlist']);
        if (message.startsWith('PLAY_MOVIE')) queryClient.invalidateQueries([siteId, 'playlist']);
        if (message.startsWith('STOP_PLAY')) queryClient.invalidateQueries([siteId, 'playlist']);
        if (message.startsWith('NEW_SITE') && message.includes(siteId)) queryClient.invalidateQueries([siteId]);
        if (message.startsWith('NO_SITE') && message.includes(siteId)) queryClient.invalidateQueries([siteId]);
    }, [queryClient, siteId]));

    return (
        <MoviesContext.Provider value={undefined}>
            {children}
        </MoviesContext.Provider>
    );
};
