import { createContext, FC, PropsWithChildren, useContext } from "react";
import { Site } from '../api';
import { useSite as useSiteQuery } from '../api/queries/use-site';
import { UseQueryResult } from 'react-query';

export const SiteContext = createContext<UseQueryResult<Site, unknown>>({
  data: undefined,
  error: null,
  isError: false,
  isIdle: true,
  isLoading: false,
  isLoadingError: false,
  isRefetchError: false,
  isSuccess: false,
  status: 'idle',
} as UseQueryResult<Site, unknown>);

export const SiteProvider: FC<PropsWithChildren> = ({ children }) => (
  <SiteContext.Provider value={useSiteQuery()}>
    {children}
  </SiteContext.Provider>
);

export function useSite() {
  return useContext(SiteContext);
}
