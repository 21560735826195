import { FC, ReactNode } from 'react';

import './styles.scss';

export interface ShapePreviewProps {
    shape: ReactNode;
}

export const ShapePreview: FC<ShapePreviewProps> = ({ shape }) => (
    <div className="shape-preview">
        {shape}
    </div>
);
