import {FC} from "react";

interface ProgressProps {
    progress: number;
}

export const Progress: FC<ProgressProps> = ({progress}) => {
    return (
        <div className="progress-bar-container">
            <div style={{width: progress + "%"}} className="progress"></div>
        </div>
    );
}
