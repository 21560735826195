import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

export const defaultSiteId = 'prod';
export const SiteIdContext = createContext(defaultSiteId);

export const SiteIdProvider: FC<PropsWithChildren> = ({ children }) => {
  const [params] = useSearchParams();

  const siteParam = params.get('site');

  const [siteId, setSiteId] = useState(siteParam ?? defaultSiteId);


  useEffect(() => void (!!siteParam && setSiteId(siteParam)), [siteParam]);

  return (
    <SiteIdContext.Provider value={siteId}>
      {children}
    </SiteIdContext.Provider>
  );
};

export function useSiteId() {
  return useContext(SiteIdContext);
}
