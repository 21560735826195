import { useEffect } from 'react';

export function useWebsocketListener<K extends keyof WebSocketEventMap>(
    socket: WebSocket, 
    eventName: K, 
    listener: (event: WebSocketEventMap[K]) => unknown
) {
    useEffect(() => {
        socket.addEventListener(eventName, listener);

        return () => socket.removeEventListener(eventName, listener);
    }, [socket, eventName, listener]);
}
