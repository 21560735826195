import { FC, useMemo } from "react";
import { useMovies, usePlaylist } from '../../api';
import { MovieList } from '../Movie-List/Movie-List';
import { Simulation } from '../Simulation/Simulation';
import './styles.scss';
import { Box } from '@mui/material';

export const Player: FC = () => {
    const { data: movies } = useMovies();
    const { data: playlist, isLoading } = usePlaylist();

    const current = useMemo(() => {
        if (!playlist || !playlist.current || !movies) return undefined;

        return movies.find((movie) => movie.id === playlist.current?.movieId);
    }, [playlist, movies]);

    const queue = useMemo(() => {
        if (!playlist || !playlist.queue || !movies) return undefined;

        const ids = playlist.queue.map(({ movieId }) => movieId);

        return movies.filter((movie) => ids.includes(movie.id));
    }, [playlist, movies]);

    return (
        <div className="player">

            {isLoading && (
                <div className="loader" />
            )}
            {current && (
                <>
                    <Simulation movie={current} />
                    <MovieList movies={queue} noQueue={true} path="/home" />
                </>
            )}
            {!isLoading && !playlist && (
                <>
                    <Box display="flex" justifyContent="center">
                        <span className="quiet">Warteschlange ist leer</span>
                    </Box>
                </>
            )}
        </div >
    );
};
