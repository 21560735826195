import Cast from '@mui/icons-material/Cast';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import { IconButton, Tooltip } from "@mui/material";
import { ComponentProps, memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Movie, useContent, useMovieQueue } from "../../api";
import { SiteGuard } from '../../SiteGuard';
import './styles.scss';

interface Props extends ComponentProps<'div'> {
    item: Movie;
    path: string;
    noQueue?: boolean;
}

export const MovieListItem = memo<Props>(({ item, path, noQueue, ...props }) => {
    const { data: content } = useContent(item.contentId);

    const navigate = useNavigate();

    const handlePreview = useCallback(() => {
        navigate({
            pathname: `${path}/${item.id}`,
            search: window.location.search,
        });
    }, [navigate, path, item]);

    const { mutate: queue } = useMovieQueue();

    const handleQueue = useCallback(() => {
        queue(item.id);
    }, [queue, item]);

    if (!content) return null;

    return (
        <div {...props} className="movie-list-item">
            <div className="movie-list-item-title">{content.title || <span className="quiet">Kein Titel</span>}</div>
            <div className="movie-list-item-actions">
                <div>
                    <IconButton onClick={handlePreview}>
                        <PlayCircleOutline />
                    </IconButton>
                </div>
                {!noQueue && (
                    <div>
                        <SiteGuard
                            loading={(
                                <Tooltip title="Nicht verfügbar" placement="bottom">
                                    <span>
                                        <IconButton disabled={true}>
                                            <Cast />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                            fallback={(
                                <Tooltip title="Nicht verfügbar">
                                    <span>
                                        <IconButton disabled={true}>
                                            <Cast />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            )}
                        >
                            <IconButton onClick={handleQueue}>
                                <Cast />
                            </IconButton>
                        </SiteGuard>
                    </div>
                )}
            </div >
        </div >
    );
}, (prev, next) => prev.item.id === next.item.id);
