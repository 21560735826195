import { FC } from "react";
import { useMovie } from '../../api';
import { Simulation } from '../Simulation/Simulation';
import './styles.scss';

interface PreviewProps {
    movieId: string;
}

export const Preview: FC<PreviewProps> = ({ movieId }) => {
    const { data: movie } = useMovie(movieId);


    if (!movie) return null;

    return <Simulation movie={movie} />;
};
