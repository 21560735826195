import { FC, useCallback } from 'react';
import { AnimationContent } from '../../api';
import { AnimationPreview, AnimationPreviewProps } from '../../components/Animation-Preview/Animation-Preview';
import { EditorPageProps } from './editor.types';

import './animation.scss';

interface IAnimationOption {
    value: AnimationContent<string> & Record<string, unknown>;
    label: string;
    from: string;
    to: string;
    oscillate?: boolean;
}

const defaultAnimationValue: AnimationContent<string> = { type: 'animation:static', duration: 5000, children: [] };

export const animationOptions: IAnimationOption[] = [
    {
        label: 'Scroll (Links)',
        value: { ...defaultAnimationValue, id: 1, type: 'animation:scroll', direction: 'LEFT' },
        from: "translateZ(-100px) translateX(400px)",
        to: "translateZ(-100px) translateX(-400px)",
    },
    {
        label: 'Scroll (Rechts)',
        value: { ...defaultAnimationValue, id: 2, type: 'animation:scroll', direction: 'RIGHT' },
        from: "translateZ(-100px) translateX(-400px)",
        to: "translateZ(-100px) translateX(400px)",
    },
    {
        label: 'Scroll (Hoch)',
        value: { ...defaultAnimationValue, id: 3, type: 'animation:scroll', direction: 'UP' },
        from: "translateZ(-100px) translateY(400px)",
        to: "translateZ(-100px) translateY(-400px)",
    },
    {
        label: 'Scroll (Runter)',
        value: { ...defaultAnimationValue, id: 4, type: 'animation:scroll', direction: 'DOWN' },
        from: "translateZ(-100px) translateY(-400px)",
        to: "translateZ(-100px) translateY(400px)",
    },
    {
        label: 'Scroll (Vorwärts)',
        value: { ...defaultAnimationValue, id: 5, type: 'animation:scroll', direction: 'FRONT' },
        from: "translateZ(-500px)",
        to: "translateZ(0px)",
    },
    {
        label: 'Scroll (Rückwärts)',
        value: { ...defaultAnimationValue, id: 6, type: 'animation:scroll', direction: 'BACK' },
        from: "translateZ(0px)",
        to: "translateZ(-500px)",
    },
    {
        label: 'Drehen (Vorwärts)',
        value: { ...defaultAnimationValue, id: 7, type: 'animation:rotation', directions: ['SPIN'] },
        from: "translateZ(-100px) rotateX(0deg)",
        to: "translateZ(-100px) rotateX(360deg)",
    },
    {
        label: 'Drehen (Seitswärts)',
        value: { ...defaultAnimationValue, id: 8, type: 'animation:rotation', directions: ['TURN'] },
        from: "translateZ(-100px) rotateY(0deg)",
        to: "translateZ(-100px) rotateY(360deg)",
    },
    {
        label: 'Rollen',
        value: { ...defaultAnimationValue, id: 9, type: 'animation:rotation', directions: ['ROLL'] },
        from: "translateZ(-100px) rotateZ(0deg)",
        to: "translateZ(-100px) rotateZ(360deg)",
    },
    {
        label: 'Welle',
        value: { ...defaultAnimationValue, id: 10, type: 'animation:wave', strength: 1.5 },
        from: "translateZ(-100px) translateY(40px)",
        to: "translateZ(-100px) translateY(-40px)",
        oscillate: true,
    },
];

export const AnimationEditor: FC<EditorPageProps> = ({ values, onPrevious, onNext, onPage, onBlur, onChange, setFieldValue, progress, ...props }) => {
    const changeAnimation = useCallback((value: AnimationContent<string>) => {
        setFieldValue?.('animation', value);
    }, [setFieldValue]);

    return (
        <>
            <div className="page-container">
                <div className="animation-choices">
                    {animationOptions.map((ao, i) => (
                        <AnimationOption
                            selected={ao.value.id === values.animation.id}
                            onClick={changeAnimation}
                            value={ao.value}
                            label={ao.label}
                            from={ao.from}
                            to={ao.to}
                            oscillate={ao.oscillate}
                            key={i}
                        />
                    ))}
                </div>
            </div>
            <div className="page-actions">
                <button className="secondary" type="button" onClick={onPrevious}>Zurück</button>
                <button className="primary" type="button" onClick={onNext}>Weiter</button>
            </div>
        </>
    );
};

interface AnimationOptionProps extends AnimationPreviewProps {
    selected: boolean;
    onClick: (value: AnimationContent<string>) => void;
    value: AnimationContent<string>;
    label: string;
}

const AnimationOption: FC<AnimationOptionProps> = ({ value, label, onClick, selected, ...props }) => {
    const handleClick = useCallback(() => {
        onClick(value);
    }, [onClick, value]);

    return (
        <div onClick={handleClick} className="animation-choice" data-selected={selected}>
            <div className="animation-choice__title">
                {label}
            </div>
            <AnimationPreview {...props} />
        </div>
    );
};
