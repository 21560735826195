import { useQuery } from "react-query";
import { useSiteId } from '../../context/SiteIdContext';
import { Movie } from '../types';

export function useMovie(id?: string) {
    const site = useSiteId();

    return useQuery([site, 'movies', id], ({ signal }) => {
        return fetch(`/api/movies/${id}`, {signal}).then<Movie>(res => res.json());
    }, { enabled: !!id });
}
