import { TextField } from '@mui/material';
import { ChangeEvent, FC, useCallback, useEffect } from 'react';
import { TextContent } from '../../api';
import { ColorPicker } from '../../components/ColorPicker/ColorPicker';
import { EditorPageProps } from './editor.types';

const defaultTextContent = {
    type: 'text',
    text: '',
    color: '#ffffff',
    font: {
        name: 'default',
        size: 5,
        depth: 1,
    },
    spacing: 2,
    alignment: {
        width: 'START',
        depth: 'START',
        height: 'CENTER',
    }
} as unknown as TextContent;

export const TextEditor: FC<EditorPageProps> = ({ values, onPrevious, onNext, onPage, setFieldValue, progress, children, ...props }) => {
    const isText = values.main.type === 'text';
    useEffect(() => {
        if (!isText) setFieldValue('main', defaultTextContent);
    }, [setFieldValue, isText]);

    const text = values.main.text;
    const color = values.main.color;

    const changeText = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('main', { ...defaultTextContent, color, text: event.target.value });
    }, [setFieldValue, color]);

    const changeColor = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFieldValue('main', { ...defaultTextContent, text, color: event.target.value });
    }, [setFieldValue, text]);

    return (
        <>
            {children}

            <div className="page-container">
                <TextField {...props} required={true} name="text" type="text" label="Text" value={text} variant="outlined" onChange={changeText} />
                <ColorPicker {...props} name="color" label="Farbe" value={color} onChange={changeColor} />
            </div>

            <div className="page-actions">
                <button disabled={!text || !color} className="primary" type="button" onClick={onNext}>Weiter</button>
            </div>
        </>
    );
};
