import { useMutation } from 'react-query';
import { ContentRoot } from '../types';
import { useSiteId } from '../../context/SiteIdContext';

export function useContentUpload() {
    const site = useSiteId();

    return useMutation((content: ContentRoot) => fetch(`/api/contents?site=${site}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(content),
    }).then(res => res.json()));
}
