import {MovieData} from "./MovieData";
import {Nova} from "./Nova";

export class Controls  {
    state = "idle";
    frame = 0;
    repeat = true;

    private nova: Nova | null = null;
    private movie: MovieData | null = null;
    private interval: number = 0;

    constructor(private playButton: HTMLButtonElement, private pauseButton: HTMLButtonElement, private repeatToggle: HTMLButtonElement, private timeline: HTMLInputElement) {

        playButton.onclick = () => this.play();
        pauseButton.onclick = () => this.pause();

        repeatToggle.setAttribute("active", "true");
        repeatToggle.onclick = () => this.toggleRepeat();

        timeline.onpointerdown = () => {
            const previousState = this.state;
            this.pause();

            const onchange = () => {
                this.frame = parseInt(timeline.value);
                this.nova!.write(this.movie!.getFrameBytes(this.frame));
            };

            const onpointerup = () => {
                document.body.removeEventListener("pointermove", onchange);

                this.frame = parseInt(timeline.value);
                this.nova!.write(this.movie!.getFrameBytes(this.frame));

                if (previousState === "play") this.play();

                document.body.removeEventListener("pointerup", onpointerup);
            };

            document.body.addEventListener("pointermove", onchange);
            document.body.addEventListener("pointerup", onpointerup);
        };
    }

    setNova(nova: Nova) {
        this.reset();
        this.nova = nova;
    }

    setMovie(movie: MovieData) {
        this.movie = movie;
        this.timeline.max = (movie.frames - 1).toString();
    }

    play() {
        if (this.state === "play") return;
        if (!this.nova) return;
        if (!this.movie) return;

        this.state = "play";

        if (this.frame >= this.movie.frames - 1) this.frame = 0;

        this.interval = window.setInterval(() => {
            if (this.frame === this.movie!.frames) {
                if (this.repeat) this.frame = 0;
                else return this.pause();
            }

            const currentFrame = this.frame++;
            this.nova!.write(this.movie!.getFrameBytes(currentFrame));
            this.timeline.value = currentFrame.toString();
        }, 1000 / this.movie.fps);

        this.playButton.hidden = true;
        this.pauseButton.hidden = false;
    }

    pause() {
        this.state = "pause";

        clearInterval(this.interval);

        this.pauseButton.hidden = true;
        this.playButton.hidden = false;
    }

    toggleRepeat() {
        this.repeat = !this.repeat;

        if (this.repeat) this.repeatToggle.setAttribute("active", "true");
        else this.repeatToggle.removeAttribute("active");
    }

    reset() {
        this.disable();
        this.frame = 0;
        this.repeat = true;
    }

    enable() {
        this.playButton.disabled = false;
        this.pauseButton.disabled = false;
        this.repeatToggle.disabled = false;
        this.timeline.disabled = false;
    }

    disable() {
        this.pause();

        this.playButton.disabled = true;
        this.pauseButton.disabled = true;
        this.repeatToggle.disabled = true;
        this.timeline.disabled = true;
        this.timeline.value = "0";
    }
}
