import { FC, ReactNode, useCallback, useEffect } from 'react';
import { ShapeContent } from '../../api';
import { ShapePreview, ShapePreviewProps } from '../../components/ShapePreview/Shape-Preview';
import { Cube } from '../../components/Shapes/Cube';
import { Sphere } from '../../components/Shapes/Sphere';
import { EditorPageProps } from './editor.types';

import './shape.scss';

const defaultShapeValue: ShapeContent<string> = { type: 'shape:cube', children: [] };
const defaultShapeContent = {
    ...defaultShapeValue,
    type: 'shape:cube',
    size: 3,
} as unknown as ShapeContent<string> & Record<string, unknown>;

interface IShapeOption {
    value: ShapeContent<string> & Record<string, unknown>;
    label: string;
    shape: ReactNode;
}

export const shapeOptions: IShapeOption[] = [
    {
        label: 'Würfel',
        value: defaultShapeContent,
        shape: <Cube />
    },
    {
        label: 'Kugel',
        value: { ...defaultShapeValue, type: 'shape:sphere', radius: 2 },
        shape: <Sphere />
    },
];

export const ShapeEditor: FC<EditorPageProps> = ({ values, onPrevious, onNext, onPage, onBlur, onChange, setFieldValue, progress, children, ...props }) => {
    const isShape = values.main.type.startsWith('shape');
    useEffect(() => {
        if (!isShape) setFieldValue('main', defaultShapeContent);
    }, [setFieldValue, isShape]);

    const changeShape = useCallback((value: ShapeContent<string>) => {
        setFieldValue?.('main', value);
    }, [setFieldValue]);

    return (
        <>
            {children}

            <div className="page-container">
                <div className="shape-choices">
                    {shapeOptions.map((fo, i) => (
                        <ShapeOption
                            selected={fo.value.type === values.main?.type}
                            shape={fo.shape}
                            onClick={changeShape}
                            value={fo.value}
                            label={fo.label}
                            key={i}
                        />
                    ))}
                </div>
            </div>

            <div className="page-actions">
                <button className="primary" type="button" onClick={onNext}>Weiter</button>
            </div>
        </>
    );
};

interface ShapeOptionProps extends ShapePreviewProps {
    selected: boolean;
    onClick: (value: ShapeContent<string>) => void;
    value: ShapeContent<string>;
    label: string;
}

const ShapeOption: FC<ShapeOptionProps> = ({ value, label, onClick, selected, ...props }) => {
    const handleClick = useCallback(() => {
        onClick(value);
    }, [onClick, value]);

    return (
        <div onClick={handleClick} className="shape-choice" data-selected={selected}>
            <div className="shape-choice__title">
                {label}
            </div>
            <ShapePreview {...props} />
        </div>
    );
};
