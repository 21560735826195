import { FC, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface BottomBarButtonProps {
    route: string;
    icon: ReactNode;
}

export const BottomBarButton: FC<BottomBarButtonProps> = ({ route, icon }) => {
    const location = useLocation();

    return (
        <Link to={{
            pathname: route,
            search: window.location.search,
        }}>
            <button type="button" data-active={location.pathname.startsWith(route)}>
                {icon}
            </button>
        </Link>
    );
}

