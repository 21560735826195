import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import './Sphere.scss';

interface SphereProps extends ComponentProps<'div'> {
};

export const Sphere: FC<SphereProps> = (props) => (
    <div {...props} className={classNames("shape__sphere", props.className)}>
        <div className="shape__sphere__face" />
    </div>
);
