import { FC } from 'react';
import { EditorPageProps } from './editor.types';
import { TextField } from '@mui/material';

export const AuthorEditor: FC<EditorPageProps> = ({ values, onPrevious, onNext, onPage, setFieldValue, progress, ...props }) => (
    <>
        <div className="page-container">
            <TextField {...props} required={true} name="title" type="text" label="Titel" value={values.title} variant="outlined" />
            <TextField {...props} name="description" type="text" label="Beschreibung" value={values.description} variant="outlined" />
            <TextField {...props} required={true} name="creator" type="text" label="Autor" value={values.creator} variant="outlined" />
        </div>

        <div className="page-actions">
            <button className="secondary" type="button" onClick={onPrevious}>Zurück</button>
            <button disabled={!values.creator || !values.title} className="success" type="submit">Hochladen</button>
        </div>
    </>
);
