import * as THREE from "three";
import { Site } from '../../api';

export class Nova {

    public x: number;
    public y: number;
    public z: number;

    public scene: THREE.Scene = new THREE.Scene();
    private objects: Array<Array<Array<THREE.Mesh>>> = [];

    constructor(private width: number, private height: number, private depth: number, private site: Site) {
        this.width = width;
        this.height = height;
        this.depth = depth;
        this.y = width;
        this.z = height;
        this.x = depth;
        this.init(width, height, depth);
    }

    init(width: number, height: number, depth: number) {
        this.scene.background = new THREE.Color('#1a2231');
        
        for (let z = 0; z < height; z++) {
            const realZ = this.site.target.directions.height.startsWith('-') ? height - 1 - z : z;

            this.objects[realZ] = this.objects[realZ] || [];

            for (let y = 0; y < depth; y++) {
                const realY = this.site.target.directions.depth.startsWith('-') ? y : depth - 1 - y ;

                this.objects[realZ][realY] = this.objects[realZ][realY] || [];

                for (let x = 0; x < width; x++) {
                    const geometry = new THREE.SphereGeometry(0.25);
                    const material = new THREE.MeshBasicMaterial({
                        color: 0x000000,
                    });

                    const realX = this.site.target.directions.width.startsWith('-') ? width - 1 - x : x;

                    const sphere = new THREE.Mesh(geometry, material);
                    this.scene.add(sphere);

                    sphere.position.x = x * 2 - width;
                    sphere.position.y = z * 2 - height;
                    sphere.position.z = y * 2 - depth;

                    this.objects[realZ][realY][realX] = sphere;
                }
            }
        }
    }

    write(bytes: Uint8Array) {
        let x = 0,
            y = 0,
            z = 0;

        const rowSize = 3 * this.y;
        const planeSize = rowSize * this.x;
        const cubeSize = planeSize * this.z;

        for (let i = 0; i < bytes.length; i += 3) {
            if (i !== 0) {
                if (i % rowSize === 0) {
                    y = 0;
                    x++;

                    if (i % planeSize === 0) {
                        x = 0;
                        z++;

                        if (i % cubeSize === 0) break;
                    }
                }
            }

            const red = bytes[i + 0];
            const green = bytes[i + 1];
            const blue = bytes[i + 2];
            const color = (red << 16) + (green << 8) + blue;
            (this.objects[z][x][y].material as THREE.Material).setValues({
                color,
            } as any);

            y++;
        }
    }

    shutdown() {
        for (let z = 0; z < this.height; z++) {
            for (let y = 0; y < this.width; y++) {
                for (let x = 0; x < this.depth; x++) {
                    (this.objects[z][x][y].material as THREE.Material).setValues({
                        color: 0x0,
                    } as any);
                }
            }
        }
    }
}
