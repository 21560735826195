import { FC, useEffect, useRef, useState } from "react";
import { Movie, Site } from "../../api";
import { Controls } from "./Controls";
import { MovieData } from "./MovieData";
import { Nova } from "./Nova";
import { Renderer } from "./Renderer";
import { useSite } from '../../api/queries/use-site';

interface SimulationProps {
    movie: Movie;
}

export const Simulation: FC<SimulationProps> = ({ movie }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ update }, setUpdate] = useState<{ update?: (movie: Movie) => void; }>({});

    const { data: site } = useSite();

    useEffect(() => {
        const element = ref.current;

        if (!element || !site) return;

        let canvas: HTMLElement | undefined;

        (async () => {
            const [renderer, update] = await createPreview(element, site);
            [...element.children].forEach(child => child.remove());
            canvas = element.appendChild(renderer.canvas);
            setUpdate({ update });
        })();

        return () => void canvas?.remove();
    }, [site]);

    useEffect(() => {
        if (movie) update?.(movie);
    }, [update, movie]);

    return (
        <div ref={ref} style={{ height: '400px' }} />
    );
};

export async function createMovie(m: Movie) {
    const buffer = await fetch(`/api/movies/${m.id}.raw`)
        .then(res => res.arrayBuffer());
    const bytes = new Uint8Array(buffer);

    return new MovieData(bytes, m);
}

export async function createPreview(
    element: HTMLElement,
    site: Site
): Promise<[Renderer, (movie: Movie) => Promise<void>]> {
    let nova;
    const renderer = new Renderer(element);

    const controls = new Controls(
        document.createElement("button"),
        document.createElement("button"),
        document.createElement("button"),
        document.createElement("input")
    );

    async function update(m: Movie) {
        nova = new Nova(m.dimensions.width, m.dimensions.height, m.dimensions.depth, site);

        renderer.canvas.style.width = '100%';
        renderer.renderer.setPixelRatio(element.clientWidth / element.clientHeight);
        renderer.renderer.setSize(element.clientWidth, element.clientHeight);

        const movie = await createMovie(m);

        controls.setMovie(movie);
        controls.setNova(nova);
        renderer.setNova(nova);
        renderer.start();
        controls.play();
    }

    function onWindowResize() {
        renderer.camera.aspect = element.clientWidth / element.clientHeight;
        renderer.camera.updateProjectionMatrix();

        renderer.renderer.setSize(element.clientWidth, element.clientHeight);
    }

    window.addEventListener("resize", onWindowResize, false);

    return [renderer, update];
}
