import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import classNames from 'classnames';
import { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";
import { Link } from "react-router-dom";
import './styles.scss';
import { IconButton } from '@mui/material';

interface PageProps extends ComponentProps<'div'> {
    title?: string;
    footer?: ReactNode;
}

export const Page: FC<PropsWithChildren<PageProps>> = ({ children, title, footer, ...props }) => {
    return (
        <div {...props} className={classNames(props.className, 'page')}>
            {title && (
                <>
                    <div className="page__header">
                        <h1>{title}</h1>
                    </div>
                    {title !== "NOVA OpenContent" && (
                        <Link to={{
                            pathname: '..',
                            search: window.location.search,
                        }}>
                            <div style={{ position: "absolute", top: "0.5rem", left: "1.5rem", color: "white", fontSize: "3rem" }}>
                                <IconButton>
                                    <ArrowBackIcon width="1.5rem" height="1.5rem" />
                                </IconButton>
                            </div>
                        </Link>
                    )}
                </>
            )}
            <div className="page__body">
                {children}
            </div>

            {footer && (
                <div className="page__footer">
                    {footer}
                </div>
            )}
        </div>
    );
};
