import { animated, EasingFunction, easings, useSpring } from '@react-spring/web';
import { FC, useCallback } from 'react';

import './styles.scss';

export interface AnimationPreviewProps {
    from: string;
    to: string;
    duration?: number;
    delay?: number;
    easing?: EasingFunction;
    oscillate?: boolean;
}

export const AnimationPreview: FC<AnimationPreviewProps> = ({
    from,
    to,
    duration = 5000,
    delay = 1,
    easing: easingFunc = easings.easeInOutCubic,
    oscillate,
}) => {
    const easing: EasingFunction = useCallback((t) => {
        if (oscillate) {
            t *= 2;

            if (t > 1) t = 1 - (t - 1);
        }

        let res = easingFunc(t);

        if (res < 0.00001) res = 0;

        return res;
    }, [easingFunc, oscillate]);

    let value = useSpring({
        from: { transform: from },
        to: { transform: to },
        delay,
        loop: true,
        config: {
            duration,
            easing,
        },
    });

    return (
        <div className="animation-preview">
            <animated.div className="animation-preview__box" style={value}>
                <div className="animation-preview__box__face animation-preview__box__face--front" />
                <div className="animation-preview__box__face animation-preview__box__face--back" />
                <div className="animation-preview__box__face animation-preview__box__face--right" />
                <div className="animation-preview__box__face animation-preview__box__face--left" />
                <div className="animation-preview__box__face animation-preview__box__face--top" />
                <div className="animation-preview__box__face animation-preview__box__face--bottom" />
            </animated.div>
        </div>
    );
};
