import { Form, Formik } from 'formik';
import { ComponentType, FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiteGuard } from '../SiteGuard';
import { AnimationContent, Content, useContentUpload, useMovieQueue, useMovies } from "../api";
import { BottomBar } from '../components/Bottom-Bar/Bottom-Bar';
import { Page } from "../components/Page/Page";
import { Progress } from '../components/Progress/Progress';
import { AnimationEditor, animationOptions } from './editor/Animation-Editor';
import { AuthorEditor } from './editor/Author-Editor';
import { EditorPreview } from "./editor/Editor-Preview";
import { EffectEditor } from './editor/Effect-Editor';
import { ShapeEditor } from './editor/Shape-Editor';
import { TextEditor } from './editor/Text-Editor';
import { EditorPageProps } from './editor/editor.types';

const initialValues = {
    main: {
        type: 'text',
        text: '',
        color: '#ffffff',
        font: {
            name: 'default',
            size: 5,
            depth: 1,
        },
        spacing: 2,
        alignment: {
            width: 'START',
            depth: 'START',
            height: 'CENTER',
        }
    } as unknown as Content<string>,
    title: '',
    description: '',
    creator: '',
    animation: animationOptions[0].value,
    color: '#ffffff',
    effects: [],
} as const;

export const Editor: FC = () => {
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    const { mutateAsync: uploadContent } = useContentUpload();
    const { mutateAsync: queueMovie } = useMovieQueue();

    const [contentId, setContentId] = useState<string>();
    const [movieId, setMovieId] = useState<string>();

    const nextPage = useCallback(() => setPage(p => p + 1), []);
    const prevPage = useCallback(() => setPage(p => p - 1), []);

    const upload = useCallback(async (values: typeof initialValues) => {
        nextPage();

        const contentId = await uploadContent({
            type: "ROOT",
            title: values.title,
            description: values.description,
            creator: values.creator,
            children: [
                ...values.effects,
                {
                    ...values.animation,
                    children: [
                        values.main,
                    ]
                } as unknown as AnimationContent<string>,
            ]
        });

        setContentId(contentId);
    }, [uploadContent, nextPage]);

    const { data: movies } = useMovies();

    useEffect(() => {
        if (!movies || !contentId) return;

        const movie = movies.find((movie) => movie.contentId === contentId);
        
        if (movie) {
            setMovieId(movie.id);
            nextPage();
        }
    }, [movies, contentId, nextPage])

    const handleQueue = useCallback((id: string) => {
        queueMovie(id);
        navigate({
            pathname: '/',
            search: window.location.search,
        });
    }, [queueMovie, navigate]);

    const handleRework = useCallback(() => {
        setPage(0);
        setContentId(undefined);
        setMovieId(undefined);
    }, []);

    const progress = Math.max(0.5, page) / 5 * 100;

    const [MainEditor, setMainEditor] = useState<ComponentType<EditorPageProps>>(() => TextEditor);
    
    return (
        <SiteGuard
            fallback={(
                <Page id="editor" title="Editor" footer={<BottomBar />}>
                    <h1>Editor aktuell nicht verfügbar</h1>
                </Page>
            )}
        >
            <Formik initialValues={initialValues} onSubmit={upload}>
                {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <Form className="form" onSubmit={handleSubmit}>
                        <Page id="editor" title="Editor">
                            <Progress progress={progress} />

                            {page === 0 && (
                                <MainEditor
                                    values={values}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onNext={nextPage}
                                    onPrevious={prevPage}
                                    onPage={setPage}
                                    progress={progress}
                                    setFieldValue={setFieldValue}
                                >
                                    <div className="main-editor-bar">
                                        <button type="button" onClick={() => setMainEditor(() => TextEditor)} data-active={MainEditor === TextEditor}>
                                            Text
                                        </button>
                                        <button type="button" onClick={() => setMainEditor(() => ShapeEditor)} data-active={MainEditor === ShapeEditor}>
                                            Form
                                        </button>
                                    </div>
                                </MainEditor>
                            )}
                            {page === 1 && <AnimationEditor values={values} onChange={handleChange} onBlur={handleBlur} onNext={nextPage} onPrevious={prevPage} onPage={setPage} progress={progress} setFieldValue={setFieldValue} />}

                            {page === 2 && <EffectEditor values={values} onChange={handleChange} onBlur={handleBlur} onNext={nextPage} onPrevious={prevPage} onPage={setPage} progress={progress} setFieldValue={setFieldValue} />}

                            {page === 3 && <AuthorEditor values={values} onChange={handleChange} onBlur={handleBlur} onNext={nextPage} onPrevious={prevPage} onPage={setPage} progress={progress} setFieldValue={setFieldValue} />}

                            {page === 4 && (
                                <div className="loader" />
                            )}

                            {page === 5 && !movieId && (
                                <div className="loader" />
                            )}

                            {page === 5 && movieId && (
                                <EditorPreview
                                    id={movieId}
                                    values={values}
                                    onQueue={handleQueue}
                                    onRework={handleRework}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    onNext={nextPage}
                                    onPrevious={prevPage}
                                    onPage={setPage}
                                    setFieldValue={setFieldValue}
                                    progress={progress}
                                />
                            )}
                        </Page>
                    </Form>
                )}
            </Formik>
        </SiteGuard>
    );
};
