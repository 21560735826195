import { Movie } from '../../api';

/**
 * @property {Uint8Array} bytes
 * @property {number} frames
 * @property {number} framesize
 */
export class MovieData {

    private framesize: number;
    public frames: number;
    private duration: number;

    public x: number;
    public y: number; 
    public z: number; 
    public fps: number;


    /**
     * @param {Uint8Array} bytes
     * @param {number} x
     * @param {number} y
     * @param {number} z
     * @param {number} fps
     */
    constructor(private bytes: Uint8Array, public movie: Movie) {
        this.bytes = bytes;
        this.x = movie.dimensions.width;
        this.y = movie.dimensions.height;
        this.z = movie.dimensions.depth;
        this.fps = movie.fps < 0 ? 25 : movie.fps;;

        this.framesize = 3 * this.x * this.y * this.z;
        this.frames = movie.frames;
        this.duration = this.frames / this.fps;
    }

    getFrameBytes(i: number) {
        const slicesize = this.framesize;
        const slicestart = i * slicesize;
        const sliceend = slicestart + slicesize;

        if (slicestart < 0 || sliceend > this.bytes.byteLength)
            throw new Error("Out of bounds");

        return this.bytes.slice(slicestart, sliceend);
    }
}
