import { FC } from "react";
import { BottomBar } from '../components/Bottom-Bar/Bottom-Bar';
import { Page } from "../components/Page/Page";
import { Player } from '../components/Player/Player';

export const Home: FC = () => {
    return (
        <Page
            title="NOVA OpenContent"
            footer={(
                <BottomBar />
            )}
        >
            <Player/>
        </Page>
    );
};
