import classNames from 'classnames';
import { ComponentProps, FC, useMemo } from 'react';

import './Rain.scss';

interface RainProps extends ComponentProps<'div'> {
};

export const Rain: FC<RainProps> = (props) => (
    <div {...props} className={classNames("rain-effect", props.className)}>
        {Array.from({ length: 50 }).map((_, i) => (
            <RainDrop key={i} />
        ))}
    </div>
);

export const RainDrop: FC = () => {
    const x = useMemo(() => Math.random() * 100, []);
    const y = useMemo(() => Math.random() * 100, []);

    return <div className="rain-effect__drop" style={{ left: `${x}%`, top: `${-y}px` }} />
}
