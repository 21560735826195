import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { FC } from 'react';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes
} from "react-router-dom";
import { WebSocketProvider } from './context';
import { MoviesProvider } from './context/NotificationContext';
import { SiteProvider } from './context/SiteContext';
import { SiteIdProvider } from './context/SiteIdContext';
import { Editor } from "./routes/Editor";
import { Gallery } from "./routes/Gallery";
import { Home } from "./routes/Home";
import { PreviewPage } from './routes/PreviewPage';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export const App: FC = () => {
    return (
        <ThemeProvider theme={darkTheme}>
            <BrowserRouter>
                <SiteIdProvider>
                    <WebSocketProvider>
                        <SiteProvider>
                            <MoviesProvider>
                                <Routes>
                                    <Route path="home">
                                        <Route index={true} element={<Home />} />
                                        <Route path=":movieId" element={<PreviewPage />} />
                                    </Route>
                                    <Route path="editor" element={<Editor />} />
                                    <Route path="gallery">
                                        <Route index={true} element={<Gallery />} />
                                        <Route path=":movieId" element={<PreviewPage />} />
                                    </Route>
                                    <Route path="/" element={(
                                        <Navigate to={{
                                            pathname: "/home",
                                            search: window.location.search,
                                        }} replace={true} />
                                    )} />
                                </Routes>
                            </MoviesProvider>
                        </SiteProvider>
                    </WebSocketProvider>
                </SiteIdProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};
