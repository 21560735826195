import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from "react";
import { defaultSiteId, useSiteId } from './SiteIdContext';

function createSocket(host: string, path: string) {
  return new WebSocket(`https://${host}${path}`);
}

const search = new URLSearchParams(window.location.search);
let site = search.get('site') ?? defaultSiteId;

const initialSocket = createSocket("fhnw-nova.ch", `/ws/notifications/${site}`);
let socket = initialSocket;

export const WebSocketContext = createContext({
  get socket() {
    return socket;
  },
  recreate: (): void => {
    socket.close();
    socket = createSocket("fhnw-nova.ch", `/ws/notifications/${site}`);
  },
});

export const WebSocketProvider: FC<PropsWithChildren> = ({ children }) => {
  const site = useSiteId();
  const [socket, setSocket] = useState(initialSocket);

  const recreate = useCallback(() => {
    setSocket(socket => {
      socket?.close();

      return createSocket("fhnw-nova.ch", `/ws/notifications/${site}`);
    });
  }, [site]);

  useEffect(() => recreate(), [recreate]);

  return (
    <WebSocketContext.Provider value={{ socket, recreate }}>
      {children}
    </WebSocketContext.Provider>
  );
};
