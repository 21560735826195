import { FC } from "react";
import { Movie } from '../../api';
import { MovieListItem } from "../Movie-List-Item/Movie-List-Item";
import './styles.scss';

interface MovieListProps {
    movies?: Movie[];
    path: string;
    noQueue?: boolean;
}

export const MovieList: FC<MovieListProps> = ({ movies, path, noQueue }) => {
    return (
        <div className="movie-list">
            {movies?.map((movie, i) => (
                <MovieListItem item={movie} path={path} noQueue={noQueue} key={i} />
            ))}
        </div>
    );
};
