import { FC, PropsWithChildren, ReactNode } from 'react';
import { Page } from './components/Page/Page';
import { useSite } from './context/SiteContext';

interface SiteGuardProps extends PropsWithChildren {
    loading?: ReactNode;
    fallback?: ReactNode;
}

export const SiteGuard: FC<SiteGuardProps> = ({ loading = <Page><div className="loader" /></Page>, fallback, children }) => {
    const { data: site, isLoading } = useSite();

    if (isLoading) return <>{loading}</>;


    if (!site) return <>{fallback}</>;

    return <>{children}</>;
};
