import { InputAdornment, TextField } from '@mui/material';
import { ComponentProps, FC, ReactNode, useCallback, useRef } from 'react';
import './styles.scss';

interface ColorPickerProps extends ComponentProps<'input'> {
    label: ReactNode;
}

export const ColorPicker: FC<ColorPickerProps> = ({ label, ref, ...props }) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const handleClick = useCallback(() => {
        inputRef.current?.focus();
        inputRef.current?.click();
    }, []);

    const startAdornment = (
        <InputAdornment position="start">
            <input {...props} className="color-picker--input" type="color" ref={inputRef} />
        </InputAdornment>
    );

    return (
        <div className="color-picker">
            
            <TextField
                {...props}
                type="text"
                label={label}
                variant="outlined"
                onClick={handleClick}
                InputProps={{ startAdornment }}
                size="medium"
                color="primary"
                fullWidth={true}
            />
        </div>
    );
};
