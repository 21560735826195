import { useQuery } from "react-query";
import { useSiteId } from '../../context/SiteIdContext';
import { Movie } from '../types';

export function useMovies() {
    const site = useSiteId();

    return useQuery<Movie[]>([site, 'movies', 'list'], ({ signal }) => {
        return fetch(`/api/movies?${site ? `site=${site}` : ''}`, {signal}).then<any[]>(res => res.json());
    });
}
