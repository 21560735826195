import { useParams } from 'react-router-dom';
import { useContent, useMovie } from '../api';
import { BottomBar } from '../components/Bottom-Bar/Bottom-Bar';
import { Page } from '../components/Page/Page';
import { Simulation } from '../components/Simulation/Simulation';

export const PreviewPage = () => {
    const { movieId } = useParams();

    const { data: movie } = useMovie(movieId);
    const { data: content } = useContent(movie?.contentId);

    return (
        <Page title={content?.title || 'Movie'}
            footer={(
                <BottomBar />
            )}
        >
            {movie && <Simulation movie={movie} />}
        </Page>
    );
};