import { useQuery } from "react-query";
import { useSiteId } from "../../context/SiteIdContext";
import { Playlist } from "../types/playlist";

export function usePlaylist() {
  const site = useSiteId();

  return useQuery<Playlist>([site, "playlist"], ({ signal }): Promise<Playlist> => {
    return fetch(`/api/sites/${site}/playlist`, { signal }).then<Playlist>(
      (res) => res.json()
    );
  });
}
