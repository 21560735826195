import { useQuery } from "react-query";
import { useSiteId } from '../../context/SiteIdContext';
import { Site } from '../types';

export function useSite() {
    const site = useSiteId();

    return useQuery<Site>([site], ({ signal }) => {
        return fetch(`/api/sites/${site}`, {signal}).then<Site>(res => res.json());
    });
}
