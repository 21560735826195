import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { EffectContent } from '../../api';
import { ShapePreview, ShapePreviewProps } from '../../components/ShapePreview/Shape-Preview';
import { EditorPageProps } from './editor.types';
import { Rain } from '../../components/Effects/Rain';
import './effect.scss';

interface IEffectOption {
    value: EffectContent<string> & Record<string, unknown>;
    label: string;
    effect: ReactNode;
}

export const effectOptions: IEffectOption[] = [
    {
        label: 'Regen',
        value: { type: 'effect:rain', children: [] },
        effect: <Rain />
    },
];

export const EffectEditor: FC<EditorPageProps> = ({ values, onPrevious, onNext, onPage, onBlur, onChange, setFieldValue, progress, children, ...props }) => {
    const [state, setState] = useState<EffectContent<string>[]>([]);

    useEffect(() => {
        setFieldValue('effects', state);
    }, [setFieldValue, state]);

    const toggleEffect = useCallback((value: EffectContent<string>) => {
        setState(s => {
            if (s.includes(value))
                return s.filter((val) => val !== value);

            return [...s, value];
        });
    }, []);

    return (
        <>
            {children}

            <div className="page-container">
                <h2>Effekte</h2>

                <div className="effect-choices">
                    {effectOptions.map((eo, i) => (
                        <EffectOption
                            selected={values.effects.includes(eo.value)}
                            shape={eo.effect}
                            onClick={toggleEffect}
                            value={eo.value}
                            label={eo.label}
                            key={i}
                        />
                    ))}
                </div>
            </div>

            <div className="page-actions">
                <button className="secondary" type="button" onClick={onPrevious}>Zurück</button>
                <button className="primary" type="button" onClick={onNext}>Weiter</button>
            </div>
        </>
    );
};

interface EffectOptionProps extends ShapePreviewProps {
    selected: boolean;
    onClick: (value: EffectContent<string>) => void;
    value: EffectContent<string>;
    label: string;
}

const EffectOption: FC<EffectOptionProps> = ({ value, label, onClick, selected, ...props }) => {
    const handleClick = useCallback(() => {
        onClick(value);
    }, [onClick, value]);

    return (
        <div onClick={handleClick} className="effect-choice" data-selected={selected}>
            <div className="effect-choice__title">
                {label}
            </div>
            <ShapePreview {...props} />
        </div>
    );
};
