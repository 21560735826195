import { useQuery } from "react-query";
import { useSiteId } from '../../context/SiteIdContext';
import { ContentRoot } from "../types";

export function useContent(id?: string) {
    const site = useSiteId();

    return useQuery([site, 'contents', id], ({ signal }) => {
        return fetch(`/api/contents/${id}`, {signal}).then<ContentRoot>(res => res.json());
    }, { enabled: !!id });
}
