import * as THREE from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {Nova} from "./Nova";

export class Renderer {
    public renderer: THREE.WebGLRenderer;
    public controls: OrbitControls;
    public canvas: HTMLCanvasElement;
    private frame: number = 0;
    private state = "stopped";
    public camera: THREE.PerspectiveCamera;
    private nova: Nova | undefined;

    constructor(parent: HTMLElement) {
        this.camera = new THREE.PerspectiveCamera(
            70,
            parent.clientWidth / parent.clientHeight,
            0.1,
            1000,
        );
        this.renderer = new THREE.WebGLRenderer();
        this.controls = new OrbitControls(
            this.camera,
            this.renderer.domElement,
        );

        this.renderer.setSize(parent.clientWidth, parent.clientHeight);
        this.canvas = this.renderer.domElement;
    }

    setNova(nova: Nova) {
        this.nova = nova;
        this.updatePosition(0, nova.z * 2, nova.y * 5);
    }

    updatePosition(x: number, y: number, z: number) {
        this.camera.position.x = x;
        this.camera.position.y = y;
        this.camera.position.z = z;

        this.controls.update();
    }

    start() {
        this.state = "started";
        this.animate();
    }

    stop() {
        cancelAnimationFrame(this.frame);
        this.state = "stopped";
        this.renderer.clear();
    }

    animate() {
        if (this.state === "stopped") return;
        if (!this.nova) return;

        this.frame = requestAnimationFrame(() => this.animate());
        this.controls.update();
        this.renderer.render(this.nova.scene, this.camera);
    }
}
