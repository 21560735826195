import classNames from 'classnames';
import { ComponentProps, FC } from 'react';

import './Cube.scss';

interface CubeProps extends ComponentProps<'div'> {
};

export const Cube: FC<CubeProps> = (props) => (
    <div {...props} className={classNames("shape__cube", props.className)}>
        <div className="shape__cube__face shape__cube__face--front" />
        <div className="shape__cube__face shape__cube__face--back" />
        <div className="shape__cube__face shape__cube__face--right" />
        <div className="shape__cube__face shape__cube__face--left" />
        <div className="shape__cube__face shape__cube__face--top" />
        <div className="shape__cube__face shape__cube__face--bottom" />
    </div>
);
